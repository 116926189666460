import { Controller } from "stimulus"
import L from "leaflet"

export default class extends Controller {
    connect() {

      var TypeIcon = L.Icon.extend({
          options: {
            shadowUrl: '/flags/ena.png',
            shadowAnchor: [1, 20],
            iconSize:     [26, 40], // size of the icon
            iconAnchor:   [13, 38], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -41] // point from which the popup should open relative to the iconAnchor
          }
      });

      var schoolIcon = new TypeIcon({iconUrl: '/flags/skolas.png'})

      var map = L.map("map").setView([56.8801729, 24.6057484], 7);
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoicGF0aXppdnMiLCJhIjoiY2ppaGlwaHU0MThiYjN2b2NvdHp5c3JqeCJ9.U7-N2HT0Cz7ekwr6kF_-dQ'
      }).addTo(map);


      fetch("/sprouts/1.json")
        .then(response => response.json())
        .then(data => {
          data.forEach(function (item, index) {

            var icon = schoolIcon
            var marker = L.marker([item.latitude, item.longitude], {icon: icon}).addTo(map);
            marker.bindPopup("<b>" + item.school_name.replaceAll('\\', '') + "</b><br>" + "</i>" + item.event_date.replaceAll('\\', '') + "</i><br>" + item.place.replaceAll('\\', '') +"<br>" + item.description.replaceAll('\\', ''))
          });
        })
        map.addControl(new L.Control.Fullscreen({
          pseudoFullscreen: true,
          title: {
            'false': 'Skats pilnekrāna režīmā',
            'true': 'Iziet no pilnekrāna režīma'
          }
        }));
  }
}

