import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 
                        "form", "status", "toast", "updated", "self_assessment", "self_assessment_fields",
                        "first_level", "second_level", "third_level", "fourth_level"
                        ]

    connect() {
        this.calculateResults()
    }

    calculateResults() {
        if (this.hasSelf_assessmentTarget) {
            let firstLevel = 0
            let secondLevel = 0
            let thirdLevel = 0
            let fourthLevel = 0
            let totalItems = this.self_assessmentTarget.dataset.count
            console.log(this.self_assessmentTarget.dataset.count)

            const selectedValues = this.self_assessment_fieldsTarget.querySelectorAll("input[type=radio]:checked")
            selectedValues.forEach(function (answer) {
                if(answer.value == 1) {
                    firstLevel += 1
                  jQuery(answer).parents(".question-group").find(".description small").hide()
                  jQuery(answer).parents(".question-group").find(".description_"+ 1).show()
                } else if (answer.value == 2) {
                  jQuery(answer).parents(".question-group").find(".description small").hide()
                  jQuery(answer).parents(".question-group").find(".description_"+ 2).show()
                    secondLevel += 1
                } else if (answer.value == 3) {
                  jQuery(answer).parents(".question-group").find(".description small").hide()
                  jQuery(answer).parents(".question-group").find(".description_"+ 3).show()
                    thirdLevel += 1
                } else if (answer.value == 4) {
                  jQuery(answer).parents(".question-group").find(".description small").hide()
                  jQuery(answer).parents(".question-group").find(".description_"+ 4).show()
                    fourthLevel += 1
                }
            })

            let firstLevelPercentage = (firstLevel / totalItems * 100)
            let secondLevelPercentage = (secondLevel / totalItems * 100)
            let thirdLevelPercentage = (thirdLevel / totalItems * 100)
            let fourthLevelPercentage = (fourthLevel / totalItems * 100)

            this.first_levelTarget.textContent = firstLevelPercentage.toFixed(0) + "%"
            this.second_levelTarget.textContent = (secondLevelPercentage + thirdLevelPercentage + fourthLevelPercentage).toFixed(0) + "%"
            this.third_levelTarget.textContent = (thirdLevelPercentage + fourthLevelPercentage).toFixed(0) + "%"
            this.fourth_levelTarget.textContent = (fourthLevelPercentage).toFixed(0) + "%"

            console.log("first: " + firstLevelPercentage)
            console.log("second: " + secondLevelPercentage)
            console.log("third: " + thirdLevelPercentage)
            console.log("fourth: " + fourthLevelPercentage)
        }
    }

    save() {
        this.statusTarget.textContent = "Saglabā..."
        jQuery(this.toastTarget).toast("show")
    }

    success(event) {
        const [data, _status, _xhr] = event.detail;
        this.updatedTarget.textContent = data.updated_at
        this.setStatus("Atskaite saglabāta!")
    }

    error() {
        this.setStatus("Kļūda saglabājot!")
    }

    setStatus(message) {
        this.statusTarget.textContent = message
    }
}
